import React, { useEffect, useState } from "react";
import Activate from "../Templates/Auth/Activate/Activate";
import useFetch from "../Hooks/useFetch";
import { activateURL } from "../Hooks/URL";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

function ActivatePage() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [cookies, setCookie] = useCookies(["TOKEN"]);

  const { data, loading, error } = useFetch(`${activateURL}/${token}`);

  // useEffect(() => {
  //   if (cookies.TOKEN) navigate("/dashboard/");
  // });

  // useEffect(() => {
  //   if (error) navigate("/dashboard/login");
  // }, [error]);

  return <Activate />;
}

export default ActivatePage;

import "./Activate.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { images } from "../../../Assets/assetsHelper";
import ActivateForm from "../../../Components/Organisms/Forms/ActivateForm/ActivateForm";

function Activate() {
  const [t, i18n] = useTranslation("common");
  return (
    <div className="Activate">
      <div className="leftActivate">
        <img alt="Logo" className="Company_Logo" src={images.Company_Logo} />
      </div>
      <div className="rightActivate">
        <h1 className="welcome">Welcome, Activate your account now..</h1>
        <h2 className="welcome">Enter a new password for your account..</h2>
        <div className="container">
          <ActivateForm />
        </div>
      </div>
    </div>
  );
}

export default Activate;

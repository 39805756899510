import React, { useEffect, useState } from "react";
import Login from "../Templates/Auth/Login/Login";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["TOKEN"]);

  useEffect(() => {
    if (cookies.TOKEN) navigate("/dashboard/");
  }, []);

  return <Login />;
}

export default LoginPage;

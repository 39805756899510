import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CookiesProvider, useCookies } from "react-cookie";
import { useJwt } from "react-jwt";

const initialState = {
  dataRes: [],
  loadingRes: true,
  errorRes: null,
};

const ACTION = {
  API_REQUEST: "api-request",
  FETCH_DATA: "fetch-data",
  ERROR: "error",
};

function reducer(saveState, { type, payload }) {
  switch (type) {
    case ACTION.API_REQUEST:
      return { ...saveState, loadingRes: true, errorRes: null };
    case ACTION.FETCH_DATA:
      return { ...saveState, dataRes: payload, loadingRes: false };
    case ACTION.ERROR:
      return { ...saveState, loadingRes: false, errorRes: payload };
    default:
      return saveState;
  }
}
function useLogin({ url, goSave, setGoSave, newObject }) {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["TOKEN"]);
  const [saveState, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (goSave) {
      dispatch({ type: ACTION.API_REQUEST });
      setLoading(true);
      axios
        .post(url, newObject, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/app.js",
          },
        })
        .then((res) => {
          dispatch({ type: ACTION.FETCH_DATA, payload: res.data });
          setCookie("TOKEN", res.data.token, { path: "/" });
          setLoading(false);
          toast.success("Signed in Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate(`/`);
        })
        .catch((err) => {
          dispatch({ type: ACTION.ERROR, payload: err?.response });
          setLoading(false);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      setGoSave(false);
    }
  }, [goSave]);
  return { saveState, loading };
}

export default useLogin;

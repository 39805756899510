import "./Dashboard.css";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { BaseURL } from "../../../Hooks/URL";

function Dashboard({ application, dashboardData }) {
  return (
    <div className="mainContainer">
      <div className="TeacherCont">
        <div className="greyBox">
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              alt="Logo"
              crossOrigin={"anonymous"}
              className="SchoolLogo"
              src={
                dashboardData?.logo
                  ? `${BaseURL}/images/${dashboardData?.logo}`
                  : ""
              }
            />
            <h2
              style={{
                fontSize: "45px",
                margin: "0 0 10px 0",
                textAlign: "center",
              }}
            >
              Welcome To {dashboardData?.name} System
            </h2>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                width: "100%",
                maxWidth: "1200px",
                margin: "auto",
                justifyContent: "space-around",
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    md: "50%",
                    textAlign: "start",
                    maxWidth: "270px",
                  },
                }}
              >
                <h3
                  style={{
                    fontSize: "20px",
                    margin: "15px 0",
                    textAlign: "center",
                  }}
                >
                  Competition Prices:
                </h3>
                <ul className="confirmList">
                  <li>
                    <span className="listTitle">Currency:</span>{" "}
                    {dashboardData?.currency}
                  </li>
                  <li>
                    <span className="listTitle">Price By Team:</span>{" "}
                    {dashboardData?.pricePerTeamInCents
                      ? dashboardData?.pricePerTeamInCents / 100
                      : "-"}
                  </li>
                  <li>
                    <span className="listTitle">Price By Student:</span>{" "}
                    {dashboardData?.pricePerStudentInCents
                      ? dashboardData?.pricePerStudentInCents / 100
                      : "-"}
                  </li>
                  <li>
                    <span className="listTitle">Price By Groups:</span>{" "}
                    {dashboardData?.competetionPricePerGroups?.length ? (
                      <ul style={{ marginTop: "10px" }}>
                        {dashboardData?.competetionPricePerGroups?.map(
                          (item) => (
                            <li
                              style={{ margin: "0 0 0 15px", fontsize: "8px" }}
                            >
                              - {item?.members} members ={" "}
                              {item?.priceInCents / 100}
                            </li>
                          )
                        )}
                      </ul>
                    ) : (
                      "-"
                    )}
                  </li>
                  <li>
                    <span className="listTitle">Additional Category:</span>{" "}
                    {dashboardData?.additionalCategoryPriceInCents
                      ? dashboardData?.additionalCategoryPriceInCents / 100
                      : "-"}
                  </li>
                  <li>
                    <span className="listTitle">Additional Student:</span>{" "}
                    {dashboardData?.additionalStudentPriceInCents
                      ? dashboardData?.additionalStudentPriceInCents / 100
                      : "-"}
                  </li>
                </ul>
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    md: "50%",
                    textAlign: "start",
                    maxWidth: "270px",
                  },
                }}
              >
                <h3
                  style={{
                    fontSize: "20px",
                    margin: "15px 0",
                    textAlign: "center",
                  }}
                >
                  Competition Rules:
                </h3>
                <ul className="confirmList">
                  <li>
                    <span className="listTitle">Additional Student:</span>{" "}
                    {dashboardData?.additionalStudent ? "True" : "False"}
                  </li>
                  <li>
                    <span className="listTitle">Additional Category:</span>{" "}
                    {dashboardData?.additionalCategory ? "True" : "False"}
                  </li>
                  <li>
                    <span className="listTitle">Pay per:</span>{" "}
                    {dashboardData?.payPerGroup ? "( groups )" : ""}{" "}
                    {dashboardData?.payPerStudent ? "( student )" : ""}{" "}
                    {dashboardData?.payPerTeam ? "( team )" : ""}
                  </li>
                </ul>
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    md: "50%",
                    textAlign: "start",
                    maxWidth: "270px",
                  },
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    margin: "15px 0",
                  }}
                >
                  Competition Dates:
                </h3>
                <ul className="confirmList">
                  <li>
                    <span className="listTitle">First Reg. Start Date:</span>{" "}
                    {dashboardData?.startRegisterDate?.split("T")?.at(0)}
                  </li>
                  <li>
                    <span className="listTitle">Second Reg. Start Date:</span>{" "}
                    {dashboardData?.secondStageStartDate?.split("T")?.at(0)}
                  </li>
                  <li>
                    <span className="listTitle">End Reg. Date:</span>{" "}
                    {dashboardData?.endRegisterDate?.split("T")?.at(0)}
                  </li>
                </ul>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

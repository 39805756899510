import Arrow from "./Images/Button_Arrow/Arrow.png";
import Facebook_Icon from "./Images/Socail Icons/facebook.png";
import Twitter_Icon from "./Images/Socail Icons/twitter.png";
import Instagram_Icon from "./Images/Socail Icons/instagram.png";
import FacebookBlack_Icon from "./Images/Socail Icons/facebookBlack.png";
import TwitterBlack_Icon from "./Images/Socail Icons/twitterBlack.png";
import InstagramBlack_Icon from "./Images/Socail Icons/instagramBlack.png";
import Right_Arrow from "./Images/Arrows/Right_Arrow.png";
import Left_Arrow from "./Images/Arrows/Left_Arrow.png";
import ChatIcon from "./Images/Bot_Chat/bot-chat-icon.png";
import ChatCloseIcon from "./Images/Bot_Chat/bot-chat-close-icon.png";
import Company_Logo from "./Images/Company_Logo/Skill-Code-logo.png";
import Company_Logo_Hor from "./Images/Company_Logo/Hor-Logo.png";
import School1 from "./Images/Company_Logo/school1.png";
import ChatPlane from "./Images/ChatPlane/ChatPlane.png";
import HomeFrame from "./Images/HomeBack/Frame.png";
import DashGirl from "./Images/dashGirl/admin-message.png";
import TeacherPlace from "./Images/placeholders/teacher_place.png";
import StudentPlace from "./Images/placeholders/student_place.png";
import upload from "./Images/placeholders/placeholder.png";

export const images = {
  Company_Logo_Hor,
  Arrow,
  Facebook_Icon,
  Twitter_Icon,
  Instagram_Icon,
  FacebookBlack_Icon,
  TwitterBlack_Icon,
  InstagramBlack_Icon,
  Right_Arrow,
  Left_Arrow,
  ChatIcon,
  ChatCloseIcon,
  Company_Logo,
  ChatPlane,
  HomeFrame,
  School1,
  DashGirl,
  TeacherPlace,
  StudentPlace,
  upload,
};

import "./SideListItem.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function SideListItem({
  name,
  Pathname,
  icon,
  title,
  setShowMenu,
  customClass,
  nested,
  link,
  arrowRotate,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [t, i18n] = useTranslation("common");
  return (
    <li onClick={() => setShowMenu(false)}>
      <div
        className={`SideListItem ${
          location.pathname.includes(name) ||
          (name == "home" && location.pathname == Pathname)
            ? "ActiveItem"
            : ""
        } ${customClass}`}
        style={{ cursor: !nested || link ? "pointer" : "auto" }}
        onClick={() => {
          if (!nested || link) navigate(link ?? Pathname);
        }}
      >
        <h2 className="ListTitle">
          <div>
            {icon}
            {title}
          </div>
        </h2>
      </div>
    </li>
  );
}

export default SideListItem;

import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { categoriesURL, classesURL, schoolURL } from "../Hooks/URL";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import CategoryDetails from "../Templates/Home/Categories/CategoryDetails/CategoryDetails";

function CategoryDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${categoriesURL}/${id}`;

  const [category, setCategory] = useState({});
  const { data, loading, error } = useFetch(url);

  useEffect(() => {
    if (!loading && !error && data) {
      setCategory(data.data);
    }
  }, [data, loading, error]);

  return (
    <CategoryDetails
      category={category}
      role={contextData?.role}
      url={url}
      loading={loading}
    />
  );
}

export default CategoryDetailsPage;

import React from "react";
import PropTypes from "prop-types";
import { Button, IconButton } from "@mui/material";
import "./PrimaryButton.css";
import { BarLoader } from "react-spinners";

function PrimaryButton({
  classBtn,
  text = "",
  click,
  startIcon = null,
  endIcon = null,
  btnType = "",
  disabled,
  style,
  size,
  loading = false,
}) {
  return (
    <>
      {btnType !== "icon" ? (
        <Button
          type={btnType}
          size={size}
          className={"btn " + classBtn}
          onClick={click}
          startIcon={startIcon}
          endIcon={endIcon}
          disabled={disabled}
          style={style}
        >
          {loading ? (
            <div className="loadingSmallBox">
              <BarLoader size={8} color="#fff" />
            </div>
          ) : (
            text
          )}
        </Button>
      ) : (
        <IconButton
          style={style}
          fullWidth
          className={"btn " + classBtn}
          onClick={click}
        >
          {startIcon}
        </IconButton>
      )}
    </>
  );
}

PrimaryButton.propTypes = {
  variant: PropTypes.string,
  classBtn: PropTypes.string,
  text: PropTypes.string,
  click: PropTypes.func,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};

export default PrimaryButton;

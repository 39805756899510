import React, { useState } from "react";
import "./LoginForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import useLogin from "../../../../Hooks/useLogin";
import { loginURL } from "../../../../Hooks/URL";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email !!").required("Email required !!"),
  password: Yup.string()
    .min(8, "Password is too short !!")
    .required("Password required !!"),
});

function LoginForm() {
  const [t, i18n] = useTranslation("common");
  const [newObject, setNewObject] = useState({});
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading } = useLogin({
    url: loginURL,
    goSave,
    setGoSave,
    newObject,
  });

  return (
    <div className="FormWrapper">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          setNewObject(values);
          setGoSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            className="LoginFormContainer"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextFieldInput
              fullWidth
              name="email"
              type="email"
              placeholder={"Enter your email"}
              className="LoginInput"
              label="Email"
              change={handleChange}
              blur={handleBlur}
              newValue={values.email}
            />
            {errors.email && touched.email && errors.email && (
              <p className="error">
                {errors.email && touched.email && errors.email}
              </p>
            )}

            <TextFieldInput
              fullWidth
              name="password"
              type="password"
              placeholder={"Enter your password"}
              className="LoginInput"
              label="Password"
              change={handleChange}
              blur={handleBlur}
              newValue={values.password}
            />
            {errors.password && touched.password && errors.password && (
              <p className="error">
                {errors.password && touched.password && errors.password}
              </p>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Login"}
                loading={loading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "50%",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default LoginForm;

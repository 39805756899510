import React, { useEffect, useState } from "react";
import "./AddSchoolForm.css";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Checkbox, Typography } from "@mui/material";
import TextArea from "../../../Atoms/Inputs/TextArea/TextArea";
import useEdit from "../../../../Hooks/useEdit";
import useSave from "../../../../Hooks/useSave";
import { BaseURL, schoolURL } from "../../../../Hooks/URL";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { PhoneInput } from "react-international-phone";
import { MuiTelInput } from "mui-tel-input";
import { phone } from "phone";
import { images } from "../../../../Assets/assetsHelper";
import CloseIcon from "@mui/icons-material/Close";

const SchoolSchema = Yup.object().shape({
  name: Yup.string().required("Name required !!"),
  email: Yup.string().email("Invalid email !!").required("Email required !!"),
  // phone: Yup.number().required("Phone Number required !!"),
  country: Yup.string().required("Country required !!"),
  city: Yup.string().required("City required !!"),
});

function AddSchoolForm({
  Edit = false,
  school,
  schools,
  url,
  setSchool,
  setSchools,
  setOpen,
  setSchoolsLoading,
  page,
  search,
}) {
  const [file, setFile] = useState("");
  const [Phone, setPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(null);
  const [PhoneTouched, setPhoneTouched] = useState(false);
  const [whatsPhone, setWhatsPhone] = useState("");
  const [WhatsPhoneError, setWhatsPhoneError] = useState(null);
  const [sameNumber, setSameNumber] = useState(Edit ? false : true);
  const [newObject, setNewObject] = useState({});
  const [goEdit, setGoEdit] = useState(false);
  const [goSave, setGoSave] = useState(false);
  const { state, loading: editLoading } = useEdit(
    url,
    goEdit,
    setGoEdit,
    newObject,
    false,
    true,
    { function: setSchool }
  );
  const {
    saveState,
    loading: addLoading,
    newData,
  } = useSave({
    url: schoolURL,
    goSave,
    setGoSave,
    newObject,
    isImage: true,
    setNewData: {
      function: setSchools,
      page: page,
      limit: 10,
      search: search,
    },
  });

  useEffect(() => {
    if (!phone(Phone)?.isValid) setPhoneError("Valid Phone Number Required!!");
    else setPhoneError("");
  }, [Phone]);

  useEffect(() => {
    if (!phone(whatsPhone)?.isValid)
      setWhatsPhoneError("Valid WhatsApp Number Required!!");
    else setWhatsPhoneError("");
  }, [whatsPhone]);

  useEffect(() => {
    if (school) {
      setPhone(school?.phone);
      setWhatsPhone(`+${school?.whatsapp?.split("=")?.at(1)}`);
    }
  }, [school]);

  useEffect(() => {
    if (newData && !saveState.loadingRes && !newData.loading)
      setSchools(newData?.data?.data);
  }, [newData]);

  useEffect(() => {
    if (state.dataRes.status == "success") {
      setOpen(false);
    }
  }, [state]);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  const handleUploadClick = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="SmallFormWrapper">
      <Formik
        initialValues={{
          name: Edit ? school?.name ?? "" : "",
          email: Edit ? school?.email ?? "" : "",
          country: Edit ? school?.country ?? "" : "",
          city: Edit ? school?.city ?? "" : "",
          street: Edit ? school?.street ?? "" : "",
          facebook: Edit ? school?.facebook ?? "" : "",
          instagram: Edit ? school?.instagram ?? "" : "",
          site: Edit ? school?.site ?? "" : "",
          about: Edit ? school?.about ?? "" : "",
        }}
        validationSchema={SchoolSchema}
        onSubmit={(values) => {
          if (!PhoneError || (sameNumber && !WhatsPhoneError))
            if (school) {
              const prev = {
                name: school?.name ?? "",
                email: school?.email ?? "",
                phone: school?.phone ?? "",
                whatsapp_phone: `+${school?.whatsapp?.split("=")?.at(1)}` ?? "",
                country: school?.country ?? "",
                city: school?.city ?? "",
                street: school?.street ?? "",
                facebook: school?.facebook ?? "",
                instagram: school?.instagram ?? "",
                site: school?.site ?? "",
                about: school?.about ?? "",
              };
              if (file)
                setNewObject({
                  ...Object.fromEntries(
                    Object.entries({
                      ...prev,
                      ...values,
                      phone: Phone,
                      whatsapp_phone: whatsPhone,
                    }).filter(([key]) => prev[key] != values[key])
                  ),
                  logo: file,
                });
              else
                setNewObject(
                  Object.fromEntries(
                    Object.entries({
                      ...prev,
                      ...values,
                      phone: Phone,
                      whatsapp_phone: whatsPhone,
                    }).filter(([key]) => prev[key] != values[key])
                  )
                );
              setGoEdit(true);
            } else {
              setNewObject({
                logo: file,
                phone: Phone,
                whatsapp_phone: sameNumber ? Phone : whatsPhone,
                ...values,
              });
              setGoSave(true);
            }
          else {
            setPhoneTouched(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="FormContainer">
              <div className="LeftForm">
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <Typography
                      style={{ fontWeight: "500", marginBottom: "10px" }}
                    >
                      Instructor Photo:
                    </Typography>
                    {(file && file !== "null") ||
                    (school?.logo && file !== "null") ? (
                      <Button
                        component="label"
                        variant="contained"
                        color="secondary"
                        startIcon={<CloseIcon style={{ fontSize: "20" }} />}
                        className="ImageInputButton RemovePhoto"
                        onClick={() => {
                          setFile("null");
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <img
                      src={
                        file && file !== "null"
                          ? URL.createObjectURL(file)
                          : school?.logo && file !== "null"
                          ? `${BaseURL}/images/${school?.logo}`
                          : images.upload
                      }
                      style={{
                        width: "120px",
                        height: "120px",
                        cursor: "pointer",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        setFile("null");
                      }}
                    />
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon style={{ fontSize: "25" }} />}
                      className="ImageInputButton uploadButton"
                    >
                      <input
                        accept="image/*"
                        className={"ImageInput"}
                        id="contained-button-file"
                        type="file"
                        onChange={handleUploadClick}
                      />
                    </Button>
                  </div>
                </div>
                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter School Name"}
                  className="Input"
                  label={"School Name"}
                  name={"name"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.name}
                />
                {errors.name && touched.name && errors.name && (
                  <p className="error">
                    {errors.name && touched.name && errors.name}
                  </p>
                )}

                <TextFieldInput
                  fullWidth
                  type="email"
                  placeholder={"Enter School Email"}
                  className="Input"
                  label={"School Email"}
                  name={"email"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.email}
                />
                {errors.email && touched.email && errors.email && (
                  <p className="error">
                    {errors.email && touched.email && errors.email}
                  </p>
                )}

                {!Edit ? (
                  <>
                    <Checkbox
                      className="checkBox"
                      checked={sameNumber}
                      onClick={() => {
                        setSameNumber(!sameNumber);
                      }}
                      id="samePhone"
                    />
                    <label className="checkboxLabel" for="samePhone">
                      Phone Number same as Whatsapp Number
                    </label>
                  </>
                ) : (
                  ""
                )}

                <div style={{ position: "relative", marginBottom: "20px" }}>
                  <label className="labelPhone">Phone Number*</label>
                  <PhoneInput
                    label={"Phone Number"}
                    className="textfield Input phoneInput"
                    value={Phone}
                    defaultCountry="eg"
                    onChange={(e) => {
                      setPhone(e);
                    }}
                  />
                  {PhoneError && PhoneTouched ? (
                    <p className="error">{PhoneError}</p>
                  ) : (
                    ""
                  )}
                </div>

                {!sameNumber ? (
                  <div style={{ position: "relative" }}>
                    <label className="labelPhone">Whatsapp Number</label>
                    <PhoneInput
                      label={"Whatsapp Number"}
                      className="textfield Input phoneInput"
                      value={whatsPhone}
                      defaultCountry="eg"
                      onChange={(e) => {
                        setWhatsPhone(e);
                      }}
                    />
                    {WhatsPhoneError && whatsPhone ? (
                      <p className="error">{WhatsPhoneError}</p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="RightForm">
                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter School Website"}
                  className="Input"
                  label={"Website"}
                  Required={false}
                  name={"site"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.site}
                />

                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter Country"}
                  className="Input"
                  label={"Country"}
                  name={"country"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.country}
                />
                {errors.country && touched.country && errors.country && (
                  <p className="error">
                    {errors.country && touched.country && errors.country}
                  </p>
                )}

                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter City"}
                  className="Input"
                  label={"City"}
                  name={"city"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.city}
                />
                {errors.city && touched.city && errors.city && (
                  <p className="error">
                    {errors.city && touched.city && errors.city}
                  </p>
                )}

                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter Street"}
                  className="Input"
                  label={"Street"}
                  name={"street"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.street}
                />

                <TextFieldInput
                  Required={false}
                  fullWidth
                  type="text"
                  placeholder={"Enter Facebook Link"}
                  className="Input"
                  label={"Facebook Link"}
                  name={"facebook"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.facebook}
                />

                <TextFieldInput
                  Required={false}
                  fullWidth
                  type="text"
                  placeholder={"Enter Instagram Link"}
                  className="Input"
                  label={"Instagram Link"}
                  name={"instagram"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.instagram}
                />
              </div>
            </div>
            <label className="aboutLabel">About The School:</label>
            <TextArea
              classes="textAreaInput"
              label={"About"}
              name={"about"}
              change={handleChange}
              blur={handleBlur}
              newValue={values.about}
            />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={Edit ? "Edit" : "Add"}
                loading={Edit ? editLoading : addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddSchoolForm;

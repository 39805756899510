import "./SideBar.css";
import React, { useContext, useState } from "react";
import SideListItem from "../../../Molecules/SideListItem/SideListItem";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { userContext } from "../../../../Contexts/userContext";

function SideBar({ setShowMenu }) {
  const { contextData, setContextData } = useContext(userContext);

  return (
    <div className="SideBar" id="SideBar">
      <ul className="TabList">
        <SideListItem
          name={"categories"}
          Pathname={"/"}
          title="Categories"
          icon={<DashboardRoundedIcon className="ListIcon" />}
          setShowMenu={setShowMenu}
        />
        <SideListItem
          name={"applications"}
          Pathname={"/applications"}
          title="Applications"
          icon={<PeopleAltRoundedIcon className="ListIcon" />}
          setShowMenu={setShowMenu}
        />
      </ul>
    </div>
  );
}

export default SideBar;

import "./Applications.css";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
import AddTeacherForm from "../../../Components/Organisms/Forms/AddTeacherForm/AddTeacherForm";
import { BarLoader } from "react-spinners";
import { BaseURL } from "../../../Hooks/URL";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import { styled } from "@mui/material/styles";
import { images } from "../../../Assets/assetsHelper";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6a0505",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const columns = [
  {
    id: "id",
    label: "ID",
    minWidth: 50,
  },
  {
    id: "code",
    label: "Code",
    minWidth: 100,
  },
  {
    id: "organization_name",
    label: "Organization",
    minWidth: 170,
  },
  { id: "firstRegestrationDatetime", label: "First Reg.", minWidth: 150 },
  { id: "secondRegistrationDatetime", label: "Second Reg.", minWidth: 150 },
];

function Applications({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  searchData,
  search,
  setSearchData,
  setSearch,
  applications,
  navigate,
  handleChangePage,
  loading,
  pageCount,
}) {
  return (
    <div className="mainContainer">
      <div className="cont">
        <div className="header">
          <TextFieldInput
            searchIcon={true}
            Required={false}
            type="text"
            placeholder={"Search"}
            className="SearchBar"
            name={"search"}
            change={(e) => setSearchData(e.target.value)}
            newValue={searchData}
            searchAction={() => {
              if (search != searchData) {
                setSearch(searchData);
              }
            }}
            keyDown={(e) => {
              if (e.keyCode === 13)
                if (search != searchData) {
                  setSearch(searchData);
                }
            }}
          />
        </div>
        <Paper
          className="tableCont"
          sx={{ width: "fit-content", minWidth: "100%" }}
        >
          {applications && !loading ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {applications?.map((application) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={application.id}
                          onClick={() => {
                            navigate(`/applications/${application.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => {
                            const value = application[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ padding: "10px" }}
                              >
                                {column.id == "firstRegestrationDatetime" ||
                                column.id == "secondRegistrationDatetime"
                                  ? value?.split("T")?.at(0)
                                  : value ?? "-"}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!applications?.length ? (
                  <h3 style={{ textAlign: "center", margin: "50px" }}>
                    No Applications Added Yet!!
                  </h3>
                ) : (
                  ""
                )}
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageCount * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </>
          ) : (
            <div className="loadingBox">
              <BarLoader color="#6a0505" />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default Applications;

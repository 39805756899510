import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../Contexts/userContext";
import useFetch from "../Hooks/useFetch";
import { categoriesURL, classesURL, dashboardURL } from "../Hooks/URL";
import Categories from "../Templates/Home/Categories/Categories";
import Dashboard from "../Templates/Home/Dashboard/Dashboard";

function DashboardPage() {
  const { contextData, setContextData } = useContext(userContext);

  const [dashboardData, setDashboard] = useState([]);
  const { data, loading, error } = useFetch(dashboardURL);

  useEffect(() => {
    if (!loading && !error && data) {
      setDashboard(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "dashboard" });
  }, []);

  return (
    <Dashboard
      contextData={contextData}
      loading={loading}
      dashboardData={dashboardData}
    />
  );
}

export default DashboardPage;

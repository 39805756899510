import "./FormModal.css";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AddSchoolForm from "../../Organisms/Forms/AddSchoolForm/AddSchoolForm";

function FormModal({ Open, HandleClose, XButton, Form, Title, CustomStyle }) {
  return (
    <div>
      <Modal
        open={Open}
        onClose={HandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={`modalStyle ${CustomStyle}`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="FormTitle">{Title}</Typography>
            {XButton ? (
              <CloseIcon
                onClick={HandleClose}
                style={{
                  cursor: "pointer",
                }}
              />
            ) : (
              ""
            )}
          </div>
          {Form}
        </Box>
      </Modal>
    </div>
  );
}

export default FormModal;

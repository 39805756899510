import { Box } from "@mui/material";
import { images } from "../../../../Assets/assetsHelper";
import "./ApplicationsDetails.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ApplicationsDetails({ application, loading, setGo }) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      {application && !loading ? (
        <div className="TeacherCont">
          <div className="greyBox">
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <h2 style={{ fontSize: "30px", margin: "0 0 10px 0" }}>
                  Application Code: {application?.code}
                </h2>
                <DeleteForm
                  setGo={setGo}
                  title={"Are you sure to delete this application ?"}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  width: "100%",
                }}
              >
                <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                  <h3 style={{ fontSize: "20px", margin: "15px 0" }}>
                    Section 1 - Admin:
                  </h3>
                  <ul className="confirmList">
                    <li>
                      <span className="listTitle">Name:</span>{" "}
                      {application?.admin_name}
                    </li>
                    <li>
                      <span className="listTitle">Email:</span>{" "}
                      {application?.admin_email}
                    </li>
                    <li>
                      <span className="listTitle">Phone Number:</span>{" "}
                      {application?.admin_contactNumber}
                    </li>
                    <li>
                      <span className="listTitle">Role:</span>{" "}
                      {application?.admin_role}
                    </li>
                  </ul>
                </Box>
                <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                  <h3 style={{ fontSize: "20px", margin: "15px 0" }}>
                    Section 2 - Organization:
                  </h3>
                  <ul className="confirmList">
                    <li>
                      <span className="listTitle">Name:</span>{" "}
                      {application?.organization_name}
                    </li>
                    <li>
                      <span className="listTitle">Email:</span>{" "}
                      {application?.organization_email}
                    </li>
                    <li>
                      <span className="listTitle">Phone Number:</span>{" "}
                      {application?.organization_contactNumber}
                    </li>
                    <li>
                      <span className="listTitle">Country:</span>{" "}
                      {application?.organization_country?.name}
                    </li>
                    <li>
                      <span className="listTitle">City:</span>{" "}
                      {application?.organization_city?.name}
                    </li>
                    <li>
                      <span className="listTitle">Street:</span>{" "}
                      {application?.organization_street}
                    </li>
                  </ul>
                </Box>
              </Box>
            </div>
          </div>
          <h2 style={{ margin: "0 0 10px 20px" }}>Teams:</h2>
          <div className="teamsCont">
            {application?.teams?.map((team) => (
              <div className="teamBox">
                {application?.secondApplied ? (
                  <>
                    <ul className="confirmList">
                      <li>
                        <span className="listTitle">Name:</span> {team?.name}
                      </li>
                      <li>
                        <span className="listTitle">Category:</span>{" "}
                        {team?.category?.name}
                      </li>
                      <li>
                        <span className="listTitle">Age Group:</span>{" "}
                        {team?.ageGroup?.name}
                      </li>
                      <li>
                        <span className="listTitle">Coach Name:</span>{" "}
                        {team?.coach?.name}
                      </li>
                      <li>
                        <span className="listTitle">Coach Email:</span>{" "}
                        {team?.coach?.email}
                      </li>
                      <li>
                        <span className="listTitle">Coach Phone Number:</span>{" "}
                        {team?.coach?.contactNumber}
                      </li>
                      <li>
                        <span className="listTitle">Team Members:</span>
                      </li>
                    </ul>
                    <TableContainer
                      component={Paper}
                      sx={{
                        marginTop: "10px",
                      }}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              name
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Birthday
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Age
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              National ID
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {team?.students?.map((stu) => (
                            <StyledTableRow key={stu.name}>
                              <StyledTableCell align="center">
                                {stu.name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.birthday?.split("T")?.at(0)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.age}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.nationalId}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <>
                    <ul className="confirmList">
                      <li style={{ fontSize: "20px" }}>
                        <strong>Name: {team?.name}</strong>
                      </li>
                      <li style={{ padding: "0px", fontSize: "20px" }}>
                        <strong>Category: {team?.category?.name}</strong>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default ApplicationsDetails;

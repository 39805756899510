import { images } from "../../../../Assets/assetsHelper";
import "./CategoryDetails.css";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router";

function CategoryDetails({ loading, category }) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      {category && !loading ? (
        <div className="SchoolCont">
          <div className="greyBox">
            <div className="detailsHeaderBox">
              <div className="detailsHeaderBoxInside">
                <div className="SchoolDetails">
                  <h2 style={{ fontSize: "35px", margin: "0 0 10px 0" }}>
                    {category.name}
                  </h2>
                  <h3 style={{ marginTop: "20px" }}>
                    Payment Type: {category?.paymentType}
                  </h3>
                  <h3 style={{ marginTop: "20px" }}>
                    Min Students: {category?.minStudents}
                  </h3>
                  <h3 style={{ marginTop: "20px" }}>
                    Max Students: {category?.maxStudents}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <h2 style={{ margin: "0 0 10px 20px" }}>Age Groups:</h2>
          {category?.ageGroups?.map((item) => (
            <div className="greyBoxWhite">
              <div className="greyNumberBox">
                <div>
                  <h4>Age Group Name: </h4>
                  <h2>{item?.name}</h2>
                </div>
              </div>
              <div className="greyNumberBox">
                <div>
                  <h4>Min Birthday: </h4>
                  <h2>{item?.minBirthday?.split("T")?.at(0)}</h2>
                </div>
              </div>
              <div className="greyNumberBox">
                <div>
                  <h4>Max Birthday: </h4>
                  <h2>{item?.maxBirthday?.split("T")?.at(0)}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default CategoryDetails;

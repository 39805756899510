import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../Contexts/userContext";
import useFetch from "../Hooks/useFetch";
import { categoriesURL, classesURL, dashboardURL } from "../Hooks/URL";
import Categories from "../Templates/Home/Categories/Categories";
import { useNavigate } from "react-router";

function CategoriesPage() {
  const navigate = useNavigate();
  const { contextData, setContextData } = useContext(userContext);

  const [categories, setCategories] = useState([]);
  const { data, loading, error } = useFetch(categoriesURL);

  useEffect(() => {
    if (!loading && !error && data) {
      setCategories(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "categories" });
  }, []);

  return (
    <Categories
      contextData={contextData}
      loading={loading}
      categories={categories}
      navigate={navigate}
    />
  );
}

export default CategoriesPage;

import "./DeleteForm.css";
import React, { useState } from "react";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../FormModal/FormModal";

function DeleteForm({ setGo, title = "" }) {
  const [Open, setOpen] = useState(false);
  return (
    <div>
      <PrimaryButton
        classBtn={"center editButton deleteBtn"}
        text={"Delete"}
        click={() => setOpen(true)}
        style={{
          textTransform: "capitalize",
          borderRadius: "5px",
          color: "var(--text-secondary)",
          backgroundColor: "#D11A2A",
          height: "40px",
          padding: "0 30px",
          // margin: "0 20px",
        }}
      />
      <FormModal
        Open={Open}
        HandleClose={() => setOpen(false)}
        XButton={false}
        Form={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              marginTop: "15px",
            }}
          >
            <PrimaryButton
              classBtn={"center editButton"}
              text={"Cancel"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "yellowgreen",
                height: "40px",
                padding: "0 30px",
                margin: "0 20px",
              }}
              click={() => {
                setOpen(false);
              }}
            />
            <PrimaryButton
              classBtn={"center editButton deleteBtn"}
              text={"Delete"}
              click={() => {
                setGo(true);
                setOpen(false);
              }}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "#D11A2A",
                height: "40px",
                padding: "0 30px",
                margin: "0 20px",
              }}
            />
          </div>
        }
        Title={title}
        CustomStyle={"smallExtra"}
      />
    </div>
  );
}

export default DeleteForm;

import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import "./TextArea.css";
import React, { useState } from "react";

function TextArea(props) {
  const [value, setvalue] = useState(props.oldValue || "");

  function handleChange(e) {
    setvalue(e.target.value);
    props.setNewObject({ ...props.newObject, [props.name]: e.target.value });
  }

  return (
    <>
      <TextareaAutosize
        minRows={4}
        placeholder={props.placeholder}
        onChange={props.change}
        onBlur={props.blur}
        className={`${props.classes} area_text`}
        style={props.style}
        label={props.label}
        value={props.newValue}
        name={props.name}
      />
    </>
  );
}

export default TextArea;

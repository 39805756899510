import "./Login.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { images } from "../../../Assets/assetsHelper";
import LoginForm from "../../../Components/Organisms/Forms/LoginForm/LoginForm";

function Login() {
  const [t, i18n] = useTranslation("common");
  return (
    <div className="Login">
      <h1 className="welcome">Welcome To Registration Dashboard</h1>
      <div className="container">
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
